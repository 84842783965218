import { default as React } from "react";
import { Editor } from "@tinymce/tinymce-react";

const DefaultEditorSelf = (props) => {
  const [html, setHtml] = React.useState(props.content ? props.content : "");

  function onChange(content) {
    setHtml(content);
    props.onEditorChange(content);
  }

  return (
    <>
      <Editor
        apiKey="eebvexxrg3u0ohvwt9bgag135dyt8j8t7oc7oatucoozs4yi"
        value={html}
        init={{
          menubar: false,
          plugins: ["lists"],
          toolbar: "formatselect bullist numlist removeformat",

          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; padding: 0; margin:0px; color:#232323; }",
        }}
        onEditorChange={onChange}
      />
    </>
  );
};

export default DefaultEditorSelf;
