import moment from "moment";
import { DatePicker } from "antd";
import "antd/lib/date-picker/style/index.css";

export const DatePickerInput = (props: any) => {
  const onChange = (data, dateString) => {
    props.onChange(dateString);
  };

  if (props.value !== undefined) {
    return (
      <>
        <div className="language_input_label">
          <label className="input_lable">{props.label}</label>
        </div>
        <DatePicker
          showTime
          onChange={onChange}
          defaultValue={moment(props.value, "YYYY-MM-DD HH:mm:ss")}
        />
      </>
    );
  } else {
    return <div className="loading"></div>;
  }
};
