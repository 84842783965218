import { useEffect, useState } from "react";
import { useForm, Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";

import { Editor } from "components/editor/Editor";
import { Uploader } from "components/editor/Uploader";
import { MultilanguageInput } from "components/editor/MultilanguageInput";
import { DatePickerInput } from "components/editor/DatePickerInput";
import { UploaderMultiple } from "components/editor/UploaderMultiple";

export const TemplatesEdit: React.FC = () => {
  const [activeIndex, setIndex] = useState(0);

  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <div>
      <div className="page_name">
        <h3>Edit template</h3>
      </div>
      <div className="page_content_wrap">
        <form onSubmit={handleSubmit(onFinish)}>
          <div className="tabs_titles">
            <ul>
              <li>
                <div
                  onClick={() => setIndex(0)}
                  className={"tab_name" + (activeIndex == 0 ? " active" : "")}
                >
                  Content
                </div>
              </li>
            </ul>
          </div>
          <div className="tabs_self">
            <div className={"tab_item" + (activeIndex == 0 ? " active" : "")}>
              <div className="field_line">
                <label className="input_lable">Title</label>
                <input
                  {...register("title", { required: true })}
                  type="text"
                  className="simple_input"
                  placeholder="Enter title"
                />
                {errors.slug && (
                  <div className="error_message"> This field is required</div>
                )}
              </div>
            </div>
          </div>
          <div className="save_button_area">
            <button type="submit" className="blue_bttn">
              {formLoading && <span>Loading</span>}
              <span>Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
