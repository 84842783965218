import { useResource, useNavigation } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router";
const { Link, useLocation } = routerProvider;
import { useLogout } from "@pankod/refine-core";


export default function Navbar() {
    const { resources } = useResource();
    const { list } = useNavigation();
    const location = useLocation();
    const { mutate: logout } = useLogout();
    const isActive = (name: any) => {
        return location.pathname.includes(name) ? 'active' : ''
    }
    return (
        <div className="navbar">
            <div className="navbar_top">
                <Link to="/" className='logo'>
                    <></>
                </Link>
                <nav>
                    <ul>
                        {resources.map(({ name, icon }) => (
                            <li key={name}>
                                <button onClick={() => list(name)} className={"nav_link " + isActive(name) }>
                                    {icon}
                                    <span>{name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
            <div className="navbar_bottom">
                <button onClick={() => logout()} className="logout"><span>Logout</span></button>
            </div>
        </div>
    )
}