import { default as React } from "react";
import { Editor } from "@tinymce/tinymce-react";

const DEFAULT_INITIAL_DATA = () => {
  return {
    children: [
      {
        question: "",
        answer: "",
      },
    ],
  };
};

const FaqChilrden = (props) => {
  const [childrenData, setChildrenData] = React.useState(
    props.data.children.length > 0 ? props.data : DEFAULT_INITIAL_DATA
  );

  const updateChildrenData = (newData) => {
    setChildrenData(newData);
    if (props.onDataChange) {
      props.onDataChange(newData);
    }
  };

  const onAddChild = (e) => {
    const newData = {
      ...childrenData,
    };
    newData.children.push({
      question: "",
      answer: "",
    });
    updateChildrenData(newData);
  };

  const onContentChange = (index, fieldName) => {
    return (e) => {
      const newData = {
        ...childrenData,
      };
      newData.children[index][fieldName] = e.target.value;

      updateChildrenData(newData);
    };
  };

  const onRemoveChild = (index) => {
    return (e) => {
      const newItems = childrenData.children.filter((child, childIndex) => {
        return childIndex !== index;
      });

      childrenData.children = newItems;
      const newData = {
        ...childrenData,
      };

      updateChildrenData(newData);
    };
  };

  return (
    <>
      <div className="ce_gallery">
        {childrenData.children.map((child, index) => (
          <div key={index} className="ce_gallery_item">
            <div className="youtube_video_link">
              <div className="faq_preview_desc">
                <input
                  type="text"
                  placeholder="Question"
                  onChange={onContentChange(index, "question")}
                  defaultValue={child.question}
                  className="yotube_video_input"
                />
                {/* <textarea
                  placeholder="Answer"
                  onChange={onContentChange(index, "answer")}
                  defaultValue={child.answer}
                  className="yotube_video_input"
                ></textarea> */}
                <Editor
                  apiKey="eebvexxrg3u0ohvwt9bgag135dyt8j8t7oc7oatucoozs4yi"
                  value={child.answer}
                  init={{
                    menubar: false,
                    plugins: ["lists", "link"],
                    toolbar:
                      "formatselect bullist numlist removeformat link unlink",

                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; padding: 0; margin:0px; color:#232323; }",
                  }}
                  onEditorChange={(content) => {
                    onContentChange(
                      index,
                      "answer"
                    )({
                      target: {
                        value: content,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div
              onClick={onRemoveChild(index)}
              className="ce_delete_child"
            ></div>
          </div>
        ))}
        {!props.readOnly && (
          <div className="ce_add_new_child" onClick={onAddChild}>
            Add new FAQ item
          </div>
        )}
      </div>
    </>
  );
};

export default FaqChilrden;
