import ReactDOM from 'react-dom';
import Uploader from '../Uploader';


export default class VideoBlock {
  
  static get toolbox() {
    return {
      icon: `<svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g transform="translate(-1.000000, -1.000000)" stroke="#000000" stroke-width="1.5">
                  <g id="Play" transform="translate(2.500000, 2.500000)">
                      <path d="M9.5,0 C14.7459,0 19,4.25315 19,9.5 C19,14.74685 14.7459,19 9.5,19 C4.25315,19 0,14.74685 0,9.5 C0,4.25315 4.25315,0 9.5,0 Z" id="Stroke-1"></path>
                      <path d="M12.5,9.49514457 C12.5,8.68401476 8.34252742,6.08911717 7.87091185,6.55569627 C7.39929629,7.02227536 7.35394864,11.9240477 7.87091185,12.4345929 C8.38787507,12.9469326 12.5,10.3062744 12.5,9.49514457 Z" id="Stroke-3"></path>
                  </g>
              </g>
          </g>
      </svg>`,
      title: 'With Class',
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }
 
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: data.title || '',
      description: data.description || '',
      video: data.video || ''
    };

    this.uploader = new Uploader({
      config: {
        endpoints: config.endpoints || '',
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || 'image',
        types: config.types || 'video/*',
        uploader: config.uploader || undefined
      },
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error)
    });

    this.CSS = {
      wrapper: 'walkthrough-nested',
    };
 
    this.nodes = {
      holder: null,
    };
  }

  onUpload(response) {
    if (response.success && response.file) {
      var lastesUploadedChild = this.data
      lastesUploadedChild.video = response.file
      lastesUploadedChild.upload_here = false
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText) {
    this.api.notifier.show({
      message: this.api.i18n.t('Can not upload an image, try another'),
      style: 'error'
    });
  }
 
  render() {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    const onTitleChange = (e) => {
      this.data.title = e.target.value
    }

    const onDescriptionChange = (e) => {
      this.data.description = e.target.value
    }

    const onSelectFile = () => {
      this.uploader.uploadSelectedFile({
        onPreview: (src) => {
          this.data.video = {
            "url": src
          }
          rootNode.querySelector('.ce_gallery_preview video').src = this.data.video?.url
        }
      });
    }

    const onEditorChange = (content) => {
      this.data.content = content
    }


    ReactDOM.render(
      (
        <div className='ce_gallery_item ce_editor_video_block'>
          <div className='block_name'>video block</div>
          <div className='ce_gallery_preview'>
            <video src={this.data.video?.url} autoPlay={true} muted={true} controls={false} />
            <div className='ce_gallery_upload' onClick={onSelectFile}><span></span></div>
          </div>
          <div className='ce_gallery_desc inline_inputs'>
            <input type='text' placeholder='Block title' onChange={onTitleChange} defaultValue={this.data.title}/>
            <textarea placeholder='Description' onChange={onDescriptionChange} defaultValue={this.data.description}></textarea> 
          </div>
        </div>
      ),
      rootNode);
    return this.nodes.holder;
  }
 
  save() {
    console.log(this.data)
    return this.data;
  }
}
