import { useShow } from "@pankod/refine-core";
import { IContact} from "interfaces";

export const ContactShow: React.FC = () => {
    const { queryResult } = useShow<IContact>();
    const { data } = queryResult;
    const record = data?.data;
    return (
        <div>
            <div className="page_name">
                <h3>View contact message</h3>  
            </div>
            <div className="page_content_wrap">
                <div className="show_page">
                    <div className="field_line show_line">
                        <label className="input_lable">From</label>
                        <p>{record?.full_name}</p>
                    </div>
                    <div className="field_line show_line">
                        <label className="input_lable">Email</label>
                        <p><a href={`mailto:${record?.email}`}>{record?.email}</a></p>
                    </div>
                    <div className="field_line show_line">
                        <label className="input_lable">Subject</label>
                        <p>{record?.subject}</p>
                    </div>
                    <div className="field_line show_line">
                        <label className="input_lable">Message</label>
                        <p>{record?.message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
