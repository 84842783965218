import { useShow } from "@pankod/refine-core";
import { IContact } from "interfaces";

export const EnquiryFormShow: React.FC = () => {
  const { queryResult } = useShow();
  const { data } = queryResult;
  const record = data?.data;

  return (
    <div>
      <div className="page_name">
        <h3>Enquiry Form</h3>
      </div>
      <div className="page_content_wrap">
        <div className="show_page">
          <div className="field_line show_line">
            <label className="input_lable">Name of Enquirer</label>
            <p>{record?.name}</p>
          </div>
          <div className="field_line show_line">
            <label className="input_lable">Company Name</label>
            <p>{record?.company}</p>
          </div>
          <div className="field_line show_line">
            <label className="input_lable">Form</label>
            {record?.form && (
              <div>
                <ul>
                  {Object.keys(JSON.parse(record?.form)).map((key) => (
                    <li
                      key={key}
                      style={{
                        listStyle: "none",
                        marginBottom: "10px",
                      }}
                    >
                      <strong>
                        {key
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                        :{" "}
                      </strong>
                      {JSON.parse(record?.form)[key]}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
