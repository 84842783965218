import { useEffect } from "react";
import { useCustom, useApiUrl, useNavigation } from "@pankod/refine-core";
import ExpandedPage from "components/utils/ExpandedPage";

export const PageList: React.FC = () => {
  const { edit, create, push } = useNavigation();
  const apiUrl = useApiUrl();

  useEffect(() => {
    const cts = document.querySelectorAll(".ct, .tox");
    cts.forEach((ct) => {
      ct.remove();
    });
  });

  const { data, isLoading, status } = useCustom({
    url: `${apiUrl}/main_menu`,
    method: "get",
  });

  return (
    <>
      <div className="page_name">
        <h3>Pages</h3>
      </div>
      <div className="page_content_wrap">
        <div className="list_page_wrap">
          <table className="list_table">
            <thead>
              <tr>
                <th>Page name</th>
                <th>Child count</th>
                <th>Updated at</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map((page: any) => (
                <ExpandedPage
                  key={page.id}
                  page={page}
                  edit={edit}
                  push={push}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
