import React from "react";
import { useLogin } from "@pankod/refine-core";
import {
  Form,
  Input,
  Button,
} from "@pankod/refine-antd";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export interface ILoginForm {
  email: string;
  password: string;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login } = useLogin<ILoginForm>();


  return (
    <div className="login_page">
        <ToastContainer />
        <div>
            <div className="login_title">
                <b>Welcome to admin panel</b>
                <p>Please enter your email and password</p>
            </div>
            <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                    login(values);
                }}
                requiredMark={false}
                initialValues={{
                    remember: false
                }}
            >
                <div className="login_inputs">
                    <Form.Item
                        name="email"
                        rules={[{ required: true }]}
                    >
                        <Input placeholder="Email" autoComplete="email"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true }]}
                    >
                        <Input type="password" placeholder="Password" autoComplete="current-password" />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit" block className="blue_bttn">
                    Sign in
                </Button>
            </Form>
        </div>
    </div>
  );
};
