import { useState } from "react";
import Moment from "react-moment";
import { useDelete } from "@pankod/refine-core";

export default function ExpandedPage(props: any) {
  const [expanded, setExpanded] = useState(false);
  const page = props.page;
  const { mutate } = useDelete();

  const expand = (data: any) => {
    if (data > 0) {
      setExpanded(!expanded);
    }
  };

  const deleteHandler = (item) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      document.querySelector(`.table_line_${item.id}`)?.classList.add("hide");
      mutate({ id: item.id, resource: "pages" });
    }
  };

  return (
    <>
      <tr
        key={page.id}
        className={
          "upper_page" +
          (page.submenu.length > 0 ? " expanded_parent" : "") +
          (expanded ? " active" : "")
        }
        onClick={() => expand(page.submenu.length)}
      >
        <td>
          {page.title} {page.ability_to_add}
        </td>
        <td>{page.submenu.length > 0 && page.submenu.length}</td>
        <td>
          <Moment format="DD MMMM YYYY">{page.updated_at}</Moment>
        </td>
        <td>
          <div className="actions">
            <button
              className="edit_bttn"
              onClick={() => props.edit("pages", page.id)}
            ></button>
            {page.ability_to_add && (
              <button
                className="plus_bttn"
                onClick={() => props.push(`/pages/create?page_id=${page.id}`)}
              ></button>
            )}
          </div>
        </td>
      </tr>
      {page.submenu.length > 0 && (
        <>
          {page.submenu.map((subpage: any) => {
            return (
              <tr
                key={subpage.id}
                className={
                  `sub_pages table_row table_line_${subpage.id}` +
                  (expanded ? " active" : "")
                }
              >
                <td>
                  <p>{subpage.title}</p>
                </td>
                <td></td>
                <td>
                  <Moment format="DD MMMM YYYY">{subpage.updated_at}</Moment>
                </td>
                <td>
                  <div className="actions">
                    <button
                      className="edit_bttn"
                      onClick={() => props.edit("pages", subpage.id)}
                    ></button>
                    <button
                      className="delete_bttn"
                      onClick={() => deleteHandler(subpage)}
                    ></button>
                    {subpage.ability_to_add && (
                      <button
                        className="plus_bttn"
                        onClick={() =>
                          props.push(`/pages/create?page_id=${subpage.id}`)
                        }
                      ></button>
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
}
