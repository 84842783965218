import ReactDOM from "react-dom";
import Uploader from "../Uploader";

const DEFAULT_INITIAL_DATA = () => {
  return {
    title: "",
    content: "",
    class_name: "",
    image: "",
    upload_here: false,
  };
};

export default class ButtonBlock {
  static get toolbox() {
    return {
      icon: `<svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" >
          <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Iconly/Light-Outline/Paper" transform="translate(-3.000000, -2.000000)" fill="#000000">
                  <path d="M13.9755,2.0003 C14.018449,2.0003 14.0605585,2.00391613 14.1015405,2.01086034 L14.2384,2.0117 C14.4424,2.0117 14.6374,2.0947 14.7794,2.2417 L19.8444,7.5187 C19.9784,7.6577 20.0535234,7.8447 20.0535234,8.0377 L20.0535234,17.2037 C20.0714,19.7127 18.1174,21.7627 15.6044,21.8647 L7.5854,21.8657 L7.4764,21.8657 C5.02645714,21.8103286 3.06156245,19.8289196 3.00170183,17.4028786 L3.0014,6.4907 C3.0594,4.0097 5.1084,2.0117 7.5714,2.0117 L13.8494595,2.01086034 C13.8904415,2.00391613 13.932551,2.0003 13.9755,2.0003 Z M13.2254,3.5113 L7.5734,3.5117 C5.9164,3.5117 4.5404,4.8537 4.5014,6.5087 L4.5014,17.2037 C4.4644,18.9167 5.8144,20.3277 7.5104,20.3657 L15.5744,20.3657 C17.2434,20.2967 18.5654,18.9097 18.553481,17.2097 L18.5534,8.9833 L16.5435,8.9843 C14.7135,8.9793 13.2255,7.4873 13.2255,5.6593 L13.2254,3.5113 Z M13.7887,14.6084 C14.2027,14.6084 14.5387,14.9444 14.5387,15.3584 C14.5387,15.7724 14.2027,16.1084 13.7887,16.1084 L8.3887,16.1084 C7.9747,16.1084 7.6387,15.7724 7.6387,15.3584 C7.6387,14.9444 7.9747,14.6084 8.3887,14.6084 L13.7887,14.6084 Z M11.7438,10.8564 C12.1578,10.8564 12.4938,11.1924 12.4938,11.6064 C12.4938,12.0204 12.1578,12.3564 11.7438,12.3564 L8.3878,12.3564 C7.9738,12.3564 7.6378,12.0204 7.6378,11.6064 C7.6378,11.1924 7.9738,10.8564 8.3878,10.8564 L11.7438,10.8564 Z M14.7254,4.3523 L14.7255,5.6593 C14.7255,6.6633 15.5425,7.4813 16.5455,7.4843 L17.7314,7.4833 L14.7254,4.3523 Z" id="Combined-Shape"></path>
              </g>
          </g>
      </svg>`,
      title: "Button",
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: data.title || "",
      link: data.link || "",
      target_blank: data.target_blank || false,
    };

    this.uploader = new Uploader({
      config: {
        endpoints: config.endpoints || "",
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || "image",
        types:
          config.types ||
          "application/pdf,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        uploader: config.uploader || undefined,
      },
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error),
    });

    this.CSS = {
      wrapper: "walkthrough-nested",
    };

    this.nodes = {
      holder: null,
    };
  }

  onUpload(response) {
    console.log(response);
    if (response.success && response.file) {
      this.data.link = response.file.url;
    } else {
      this.uploadingFailed("incorrect response: " + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText) {
    this.api.notifier.show({
      message: this.api.i18n.t("Can not upload an image, try another"),
      style: "error",
    });
  }

  render() {
    const rootNode = document.createElement("div");
    rootNode.setAttribute("class", this.CSS.wrapper);
    this.nodes.holder = rootNode;

    const onTitleChange = (e) => {
      this.data.title = e.target.value;
    };

    const onLinkChange = (e) => {
      this.data.link = e.target.value;
    };

    const onTargetChange = (e) => {
      const { checked } = e.target;
      this.data.target_blank = checked;
    };

    const onSelectFile = () => {
      this.uploader.uploadSelectedFile({
        onPreview: (src) => {
          rootNode.querySelector(".ce_uploade_file_name").innerHTML =
            "The file is loading";
          setTimeout(() => {
            if (this.data.link.length > 0) {
              rootNode.querySelector(".ce_uploade_file_name").innerHTML =
                decodeURI(this.data.link).split("/").pop();
            }
          }, 1000);
        },
      });
    };

    ReactDOM.render(
      <div className="ce_gallery_item ce_editor_with_class">
        <div className="block_name">button</div>
        <div className="ce_gallery_desc inline_inputs">
          <input
            type="text"
            placeholder="Button title"
            onChange={onTitleChange}
            defaultValue={this.data.title}
          />
          <div
            onClick={() => {
              onSelectFile();
            }}
            className="ce_uploade_file_select"
          >
            <div className="ce_uploade_file_name">
              {this.data.link ? this.data.link.split("/").pop() : "Select file"}
            </div>
            <div className="ce_upload_file blue_bttn">Upload file</div>
          </div>
          <label className="inline_checkbox">
            <input
              type="checkbox"
              onChange={onTargetChange}
              defaultChecked={this.data.target_blank}
            />
            <p>Target blank</p>
          </label>
        </div>
      </div>,
      rootNode
    );
    return this.nodes.holder;
  }

  save() {
    console.log(this.data);
    return this.data;
  }
}
