import { useEffect, useState } from "react";
import { useForm, Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { Editor } from "components/editor/Editor";
import { Uploader } from "components/editor/Uploader";
import { UploaderMultiple } from "components/editor/UploaderMultiple";
import { MultilanguageInput } from "components/editor/MultilanguageInput";
import { DatePickerInput } from "components/editor/DatePickerInput";

export const SupplierCategoryCreate: React.FC = () => {
  const { i18n } = useTranslation();

  const [activeIndex, setIndex] = useState(0);

  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const defaulValues = {};
  (i18n.languages || []).map(
    (lang: string, index: number) => (defaulValues[lang] = "")
  );

  useEffect(() => {
    setValue("title", defaulValues);
  }, []);

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <div>
      <div className="page_name">
        <h3>Create supplier category</h3>
      </div>
      <div className="page_content_wrap">
        <form onSubmit={handleSubmit(onFinish)}>
          <div className="tabs_titles">
            <ul>
              <li>
                <div
                  onClick={() => setIndex(0)}
                  className={"tab_name" + (activeIndex == 0 ? " active" : "")}
                >
                  Content
                </div>
              </li>
            </ul>
          </div>
          <div className="tabs_self">
            <div className={"tab_item" + (activeIndex == 0 ? " active" : "")}>
              <div className="field_line">
                <Controller
                  {...register("title", {
                    validate: (value) => value.en != "",
                  })}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultilanguageInput
                      label="Supplier category title"
                      placeholder="Enter supplier category title"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.title && (
                  <div className="error_message">This field is required</div>
                )}
              </div>
            </div>
            <div
              className={"tab_item" + (activeIndex == 1 ? " active" : "")}
            ></div>
            <div
              className={"tab_item" + (activeIndex == 2 ? " active" : "")}
            ></div>
          </div>
          <div className="save_button_area">
            <button type="submit" className="blue_bttn">
              {formLoading && <span>Loading</span>}
              <span>Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
