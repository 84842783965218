import { useEffect, useState } from "react";
import { useForm, Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";

import { Editor } from "components/editor/Editor";
import { Uploader } from "components/editor/Uploader";
import { UploaderMultiple } from "components/editor/UploaderMultiple";
import { MultilanguageInput } from "components/editor/MultilanguageInput";
import { DatePickerInput } from "components/editor/DatePickerInput";

export const SuppliersCreate: React.FC = () => {
  const { i18n } = useTranslation();

  const [activeIndex, setIndex] = useState(0);

  const {
    refineCore: { onFinish, formLoading, queryResult },
    register,
    handleSubmit,
    resetField,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const defaulValues = {};
  (i18n.languages || []).map(
    (lang: string, index: number) => (defaulValues[lang] = "")
  );

  const { options: suppliers_options } = useSelect({
    resource: "supplier_categories_list",
    optionLabel: "title",
    optionValue: "id",
  });

  useEffect(() => {
    setValue("title", defaulValues);
    setValue("short_description", defaulValues);
    setValue("preview", null);
    setValue("content", defaulValues);
    setValue("phone", null);
    setValue("email", null);
    setValue("facebook", null);
    setValue("linkedin", null);
    setValue("instagram", null);
    setValue("supplier_category.id", null);
  }, []);

  const onSubmit = (data) => {
    console.log(data);
    // onFinish(data)
  };

  return (
    <div>
      <div className="page_name">
        <h3>Create supplier</h3>
      </div>
      <div className="page_content_wrap">
        <form onSubmit={handleSubmit(onFinish)}>
          <div className="tabs_titles">
            <ul>
              <li>
                <div
                  onClick={() => setIndex(0)}
                  className={"tab_name" + (activeIndex == 0 ? " active" : "")}
                >
                  Content
                </div>
              </li>
            </ul>
          </div>
          <div className="tabs_self">
            <div className={"tab_item" + (activeIndex == 0 ? " active" : "")}>
              <div className="field_line">
                <Controller
                  {...register("title", {
                    validate: (value) => value.en != "",
                  })}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultilanguageInput
                      label="Supplier title"
                      placeholder="Enter supplier title"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
                {errors.title && (
                  <div className="error_message">This field is required</div>
                )}
              </div>
              <div className="field_line">
                <Controller
                  {...register("preview")}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Uploader
                      label="Preview image"
                      onChange={onChange}
                      value={value}
                    />
                  )}
                />
              </div>
              <div className="field_line">
                <Controller
                  {...register("short_description")}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultilanguageInput
                      label="Supplier short description"
                      placeholder="Enter supplier short description"
                      onChange={onChange}
                      value={defaulValues}
                    />
                  )}
                />
              </div>

              <div className="field_line">
                <label className="input_lable">Supplier category</label>
                <select
                  defaultValue={""}
                  {...register("supplier_category.id", { required: true })}
                  className="simple_input"
                >
                  <option value={""} disabled>
                    Please select
                  </option>
                  {suppliers_options?.map((template) => (
                    <option key={template.value} value={template.value}>
                      {template.label}
                    </option>
                  ))}
                </select>
                {errors.template && (
                  <div className="error_message">This field is required</div>
                )}
              </div>

              <div className="field_line">
                <div className="language_input_label">
                  <label className="input_lable">Supplier phone number</label>
                </div>
                <input
                  {...register("phone")}
                  type="text"
                  className="simple_input"
                  placeholder="Supplier phone number"
                />
              </div>
              <div className="field_line">
                <div className="language_input_label">
                  <label className="input_lable">Supplier Email</label>
                </div>
                <input
                  {...register("email")}
                  type="text"
                  className="simple_input"
                  placeholder="Supplier Email"
                />
              </div>
              <div className="filed_multi_col">
                <div className="field_line">
                  <div className="language_input_label">
                    <label className="input_lable">Facebook</label>
                  </div>
                  <input
                    {...register("facebook")}
                    type="text"
                    className="simple_input"
                    placeholder="Facebook"
                  />
                </div>
                <div className="field_line">
                  <div className="language_input_label">
                    <label className="input_lable">Linkedin</label>
                  </div>
                  <input
                    {...register("linkedin")}
                    type="text"
                    className="simple_input"
                    placeholder="Linkedin"
                  />
                </div>
                <div className="field_line">
                  <div className="language_input_label">
                    <label className="input_lable">Instagram</label>
                  </div>
                  <input
                    {...register("instagram")}
                    type="text"
                    className="simple_input"
                    placeholder="Instagram"
                  />
                </div>
              </div>

              <div className="field_line">
                <Controller
                  {...register("content")}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Editor
                      label="Content"
                      placeholder="Enter page content data"
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                  )}
                />
              </div>
            </div>
            <div
              className={"tab_item" + (activeIndex == 1 ? " active" : "")}
            ></div>
            <div
              className={"tab_item" + (activeIndex == 2 ? " active" : "")}
            ></div>
          </div>
          <div className="save_button_area">
            <button type="submit" className="blue_bttn">
              {formLoading && <span>Loading</span>}
              <span>Save</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
