import ReactDOM from 'react-dom';
import Uploader from './../Uploader';
import DefaultEditorSelf from './DefaultEditor';

const DEFAULT_INITIAL_DATA = () => {
  return {
    "title": "",
    "content": "",
    "class_name": "",
    "image": "",
    "upload_here": false
  }
}

export default class WithClass {
  
  static get toolbox() {
    return {
      icon: `<svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g transform="translate(0.000000, -2.000000)" stroke="#000000" stroke-width="1.5">
                  <g id="Scan" transform="translate(1.500000, 3.350100)">
                      <line x1="21" y1="9.4555" x2="0" y2="9.4555" id="Stroke-1"></line>
                      <path d="M19.1299,5.245 L19.1299,3.732 C19.1299,1.671 17.4589,1.77635684e-15 15.3969,1.77635684e-15 L14.1919,1.77635684e-15" id="Stroke-3"></path>
                      <path d="M1.8701,5.245 L1.8701,3.732 C1.8701,1.671 3.5411,1.77635684e-15 5.6031,1.77635684e-15 L6.8391,1.77635684e-15" id="Stroke-5"></path>
                      <path d="M19.1299,9.4545 L19.1299,13.5285 C19.1299,15.5905 17.4589,17.2615 15.3969,17.2615 L14.1919,17.2615" id="Stroke-7"></path>
                      <path d="M1.8701,9.4545 L1.8701,13.5285 C1.8701,15.5905 3.5411,17.2615 5.6031,17.2615 L6.8391,17.2615" id="Stroke-9"></path>
                  </g>
              </g>
          </g>
      </svg>`,
      title: 'With Class',
    };
  }

  static get enableLineBreaks() {
    return true;
  }

  static get isReadOnlySupported() {
    return true;
  }
 
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: data.title || '',
      class_name: data.class_name || '',
      image: data.image || '',
      content: data.content || '',
    };

    this.uploader = new Uploader({
      config: {
        endpoints: config.endpoints || '',
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || 'image',
        types: config.types || 'image/*',
        uploader: config.uploader || undefined
      },
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error)
    });

    this.CSS = {
      wrapper: 'walkthrough-nested',
    };
 
    this.nodes = {
      holder: null,
    };
  }

  onUpload(response) {
    if (response.success && response.file) {
      var lastesUploadedChild = this.data
      lastesUploadedChild.image = response.file
      lastesUploadedChild.upload_here = false
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText) {
    this.api.notifier.show({
      message: this.api.i18n.t('Can not upload an image, try another'),
      style: 'error'
    });
  }
 
  render() {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;

    const onTitleChange = (e) => {
      this.data.title = e.target.value
    }

    const onClassChange = (e) => {
      this.data.class_name = e.target.value
    }

    const onSelectFile = () => {
      this.uploader.uploadSelectedFile({
        onPreview: (src) => {
          this.data.image = {
            "url": src
          }
          rootNode.querySelector('.ce_gallery_preview').style.backgroundImage = `url(${this.data.image?.url})`
        }
      });
    }

    const onEditorChange = (content) => {
      this.data.content = content
    }


    ReactDOM.render(
      (
        <div className='ce_gallery_item ce_editor_with_class'>
          <div className='block_name'>with class block</div>
          <div 
            className='ce_gallery_preview' 
            style={{backgroundImage: `url(${this.data.image?.url})`}}
          >
            <div className='ce_gallery_upload' onClick={onSelectFile}><span></span></div>
          </div>
          <div className='ce_gallery_desc inline_inputs'>
            <input type='text' placeholder='Block title' onChange={onTitleChange} defaultValue={this.data.title}/>
            <DefaultEditorSelf readOnly={this.readOnly} content={this.data.content} onEditorChange={onEditorChange}  />
            <input type='text' placeholder='Class name (important field for frontend)' onChange={onClassChange} defaultValue={this.data.class_name}/> 
          </div>
        </div>
      ),
      rootNode);
    return this.nodes.holder;
  }
 
  save() {
    console.log(this.data)
    return this.data;
  }
}
