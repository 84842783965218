import { useApiUrl } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import EditorJs from "@natterstefan/react-editor-js";

import Embed from "@editorjs/embed";
import List from "@editorjs/list";
import Image from "@editorjs/image";
import Header from "@editorjs/header";
import Nested from "./tools/nested/Nested";
import Gallery from "./tools/gallery/Gallery";
import Timeline from "./tools/timeline/Timeline";
import WithClass from "./tools/with_class/WithClass";
import VideoBlock from "./tools/video_block/VideoBlock";
import ButtonBlock from "./tools/button/ButtonBlock";
import YouTubeVideo from "./tools/youtube_video_block/YouTubeVideo";
import Faq from "./tools/faq_block/Faq";

export const Editor = (props) => {
  const { i18n } = useTranslation();
  const [inputs, setInputs] = useState({});
  const [activeIndex, setIndex] = useState(0);

  useEffect(() => {
    setInputs(props.value);
  });

  let editors = {};

  const onChange = async () => {
    const values = {};
    try {
      Object.keys(editors).forEach(async (name) => {
        let outputData = await editors[name].save();
        values[name] = outputData;
        props.onChange(values);
      });
    } catch (e) {
      console.log("Saving failed: ", e);
    }
  };

  const apiUrl = useApiUrl();

  const config = {
    endpoints: {
      byFile: `${apiUrl}/attachment/upload_editor`,
    },
    additionalRequestHeaders: {
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
  };

  const configDocFiles = {
    endpoints: {
      byFile: `${apiUrl}/attachment/upload_image`,
    },
    additionalRequestHeaders: {
      Authorization: `Bearer ${localStorage.getItem("auth")}`,
    },
  };

  const Tools = {
    embed: Embed,
    list: List,
    image: {
      class: Image,
      config: config,
    },
    header: Header,
    nested: {
      class: Nested,
      config: config,
    },
    timeline: {
      class: Timeline,
      config: config,
    },
    gallery: {
      class: Gallery,
      config: config,
    },
    content_with_class: {
      class: WithClass,
      config: config,
    },
    video_block: {
      class: VideoBlock,
      config: config,
    },
    button_block: {
      class: ButtonBlock,
      config: configDocFiles,
    },
    youtube_block: {
      class: YouTubeVideo,
      config: config,
    },
    faq_block: {
      class: Faq,
      config: config,
    },
  };

  if (inputs !== undefined && JSON.stringify(inputs) !== "{}") {
    return (
      <>
        <div className="language_input_label">
          <label className="input_lable">{props.label}</label>
          <ul>
            {[...(i18n.languages || [])].map((lang, index) => (
              <li key={lang} onClick={() => setIndex(index)}>
                <div
                  className={
                    "language_switch" + (activeIndex == index ? " active" : "")
                  }
                >
                  {lang}
                </div>
              </li>
            ))}
          </ul>
        </div>
        {[...(i18n.languages || [])].map((lang, index) => (
          <div
            key={lang}
            className={
              "language_input" + (activeIndex == index ? " active" : "")
            }
          >
            <EditorJs
              data={inputs[lang] ? inputs[lang] : {}}
              onChange={onChange}
              editorInstance={(editorInstance) => {
                editors[lang] = editorInstance;
              }}
              tools={Tools}
              holder={`editor_${lang}`}
            >
              <div id={`editor_${lang}`} className="editor" />
            </EditorJs>
          </div>
        ))}
      </>
    );
  } else {
    return <div className="loading"></div>;
  }
};
