import { Refine, AuthProvider, useNotification } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router";
import dataProvider from "@pankod/refine-simple-rest";
import { useTranslation } from "react-i18next";
import axios from "axios";

// ----------------------- Layouts ----------------------- //
import { Login } from "pages/login/index";
import { Layout } from "components/layout";

// ----------------------- Pages ----------------------- //
import { PageIcon, ContactIcon, NewsIcon, EventIcon } from "icons";
import { PageList, PageEdit, PageCreate } from "pages/pages";
import { NewsList, NewsCreate, NewsEdit } from "pages/news";
import { EventList, EventCreate, EventEdit } from "pages/events";
import { ContactList, ContactShow } from "pages/contacts";

// ----------------------- Providers ----------------------- //
import { notificationProvider } from "providers/notificationProvider";
import {
  SupplierCategoryCreate,
  SupplierCategoryEdit,
  SupplierCategoryList,
} from "pages/supplier_categories";
import { SuppliersCreate, SuppliersEdit, SuppliersList } from "pages/suppliers";
import {
  VideoCategoryCreate,
  VideoCategoryEdit,
  VideoCategoryList,
} from "pages/video_categories";
import { VideoCreate, VideoEdit, VideoList } from "pages/videos";
import { TemplatesCreate, TemplatesEdit, TemplatesList } from "pages/templates";
import { EnquiryFormList, EnquiryFormShow } from "pages/enquiry_forms";

// ----------------------- API URL ----------------------- //
const API_URL = `${process.env.REACT_APP_API_URL}`;

// ----------------------- Axios ----------------------- //
const axiosInstance = axios.create();

const App: React.FC = () => {
  const { open } = useNotification();
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const authProvider: AuthProvider = {
    login: ({ email, password }) => {
      return axios
        .post(API_URL + "/users/login", {
          user: {
            email: email,
            password: password,
          },
        })
        .then((response) => {
          if (response.data.user.token) {
            localStorage.setItem(
              "auth",
              JSON.stringify(response.data.user.token)
            );
            axiosInstance.defaults.headers = {
              Authorization: `Bearer ${response.data.user.token}`,
            };
            return Promise.resolve();
          }
          return Promise.reject();
        })
        .catch((error) => {
          return Promise.reject();
        });
    },
    logout: () => {
      localStorage.removeItem("auth");
      return Promise.resolve();
    },
    checkError: (error) => {
      return Promise.reject();
    },
    checkAuth: () => {
      if (localStorage.getItem("auth")) {
        axiosInstance.defaults.headers = {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        };
        return Promise.resolve();
      }
      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(["admin"]),
  };

  return (
    <Refine
      authProvider={authProvider}
      dataProvider={dataProvider(API_URL, axiosInstance)}
      // i18nProvider={i18nProvider}
      routerProvider={routerProvider}
      notificationProvider={notificationProvider}
      resources={[
        {
          name: "pages",
          list: PageList,
          create: PageCreate,
          edit: PageEdit,
          icon: PageIcon,
        },
        {
          name: "news",
          list: NewsList,
          create: NewsCreate,
          edit: NewsEdit,
          icon: NewsIcon,
        },
        {
          name: "events",
          list: EventList,
          create: EventCreate,
          edit: EventEdit,
          icon: EventIcon,
        },
        {
          name: "supplier_categories",
          list: SupplierCategoryList,
          create: SupplierCategoryCreate,
          edit: SupplierCategoryEdit,
          icon: EventIcon,
        },
        {
          name: "suppliers",
          list: SuppliersList,
          create: SuppliersCreate,
          edit: SuppliersEdit,
          icon: EventIcon,
        },
        {
          name: "video_categories",
          list: VideoCategoryList,
          create: VideoCategoryCreate,
          edit: VideoCategoryEdit,
          icon: EventIcon,
        },
        {
          name: "videos",
          list: VideoList,
          create: VideoCreate,
          edit: VideoEdit,
          icon: EventIcon,
        },
        {
          name: "contacts",
          list: ContactList,
          show: ContactShow,
          icon: ContactIcon,
        },
        {
          name: "templates",
          list: TemplatesList,
          create: TemplatesCreate,
          edit: TemplatesEdit,
          icon: PageIcon,
        },

        {
          name: "enquiry_forms",
          list: EnquiryFormList,
          show: EnquiryFormShow,
          icon: ContactIcon,
        },
      ]}
      Layout={Layout}
      LoginPage={Login}
    />
  );
};

export default App;
