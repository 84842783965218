import { useState, useEffect } from "react";
import { useCustom, useApiUrl, useNavigation, useDelete } from "@pankod/refine-core";
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import RouterProvider from "@pankod/refine-react-router";

export const EventList: React.FC = () => {
  const { push, edit, create } = useNavigation();
  const [page, setPage] = useState(1);
  const { mutate } = useDelete();

  const apiUrl = useApiUrl();
  const params = RouterProvider.useLocation()
  useEffect(() => {
    if(params.search){
      const routePage = Number(new URL(window.location.href).searchParams.get("page"));
      setPage(routePage);
    }
    const cts = document.querySelectorAll('.ct, .tox');
    cts.forEach(ct => {
      ct.remove();
    });
  });

  const { data, isLoading, status } = useCustom({
      url: `${apiUrl}/events`,
      config: {
        query: {
            page: page,
            limit: 15,
        },
      },
      method: "get"
  });

  const pagginationHandler = (selected) => {
    const selected_page = selected.selected + 1
    setPage(selected_page)
    push(`events?page=${selected_page}`)
  };

  const deleteHandler = (item) => {
    if (window.confirm('Are you sure you wish to delete this item?')) {
      document.querySelector(`.table_line_${item.id}`)?.classList.add('hide')
      mutate({ id: item.id, resource: "events" })
    }
  };
  
  return (
    <>
      <div className="page_name">
          <h3>Events</h3>
          <button className='blue_bttn' onClick={() => create("events")}>Create event page</button>
      </div>
      <div className="page_content_wrap">
        <div className="list_page_wrap">
          <table className="list_table">
            <thead>
              <tr>
                <th>Event title</th>
                <th>Showing date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.data.map((item: any) => (
                <tr key={item.id} className={'table_row table_line_'+item.id}>
                  <td><div className="large_litle">{item.title}</div></td>
                  <td><Moment format="DD MMMM YYYY">{item.showing_date}</Moment></td>
                  <td>
                    <div className="actions">
                      <button className='edit_bttn' onClick={() => edit("events", item.id)}></button>
                      <button className="delete_bttn" onClick={() => deleteHandler(item) }></button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {data?.data.pagination.pages > 1 && 
            <ReactPaginate
              className="paggination"
              previousClassName="pg_previous"
              nextClassName="pg_next"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={pagginationHandler}
              pageRangeDisplayed={5}
              initialPage={data?.data.pagination.page - 1}
              disableInitialCallback={true}
              pageCount={data?.data.pagination.pages}
              previousLabel="Previous"
            />
          }
        </div>
      </div>
    </>
  );

};

