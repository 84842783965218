import { default as React } from "react";

const DEFAULT_INITIAL_DATA = () => {
  return {
    children: [
      {
        title: "",
        category: "",
        date: "",
        youtube_url: "",
      },
    ],
  };
};

const YouTubeVideoChildren = (props) => {
  const [childrenData, setChildrenData] = React.useState(
    props.data.children.length > 0 ? props.data : DEFAULT_INITIAL_DATA
  );

  const updateChildrenData = (newData) => {
    setChildrenData(newData);
    if (props.onDataChange) {
      props.onDataChange(newData);
    }
  };

  const onAddChild = (e) => {
    const newData = {
      ...childrenData,
    };
    newData.children.push({
      title: "",
      category: "",
      date: "",
      youtube_url: "",
    });
    updateChildrenData(newData);
  };

  const onContentChange = (index, fieldName) => {
    return (e) => {
      const newData = {
        ...childrenData,
      };
      newData.children[index][fieldName] = e.target.value;
      updateChildrenData(newData);
    };
  };

  const onRemoveChild = (index) => {
    return (e) => {
      const newItems = childrenData.children.filter((child, childIndex) => {
        return childIndex !== index;
      });

      childrenData.children = newItems;
      const newData = {
        ...childrenData,
      };

      updateChildrenData(newData);
    };
  };

  return (
    <>
      <div className="ce_gallery">
        {childrenData.children.map((child, index) => (
          <div key={index} className="ce_gallery_item">
            <div className="youtube_video_link">
              <div className="yotube_video_preview">
                {child.youtube_url && (
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${
                      child.youtube_url.split("=")[1]
                    }`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                )}
              </div>
              <div className="yotube_video_preview_desc">
                <input
                  type="text"
                  placeholder="Title"
                  onChange={onContentChange(index, "title")}
                  defaultValue={child.title}
                  className="yotube_video_input"
                />
                <select
                  onChange={onContentChange(index, "category")}
                  className="yotube_video_input"
                  defaultValue={child.category ? child.category : "interview"}
                >
                  <option value="interview">Interview</option>
                  <option value="promo">Promo</option>
                  <option value="construction">Construction</option>
                  <option value="drone_footage">Drone footage</option>
                </select>
                <input
                  type="date"
                  placeholder="Date"
                  onChange={onContentChange(index, "date")}
                  defaultValue={child.date}
                  className="yotube_video_input"
                />
                <input
                  type="text"
                  placeholder="Youtube link"
                  onChange={onContentChange(index, "youtube_url")}
                  defaultValue={child.youtube_url}
                  className="yotube_video_input"
                />
              </div>
            </div>
            <div
              onClick={onRemoveChild(index)}
              className="ce_delete_child"
            ></div>
          </div>
        ))}
        {!props.readOnly && (
          <div className="ce_add_new_child" onClick={onAddChild}>
            Add new youtube item
          </div>
        )}
      </div>
    </>
  );
};

export default YouTubeVideoChildren;
