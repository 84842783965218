import ReactDOM from 'react-dom';
import TimelineChildren from './TimelineChildren';
import Uploader from './../Uploader';

export default class Timeline {
  
  static get toolbox() {
    return {
      icon: `<svg width="18px" height="16px" viewBox="0 0 18 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g transform="translate(-3.000000, -3.000000)" stroke="#000000" stroke-width="1.5">
                  <g transform="translate(4.000000, 4.500000)">
                      <line x1="9.14048198" y1="2.40037662" x2="15.4411992" y2="2.40037662" id="Stroke-3"></line>
                      <path d="M4.72628792,2.34625359 C4.72628792,1.05059752 3.66812728,0 2.36314396,0 C1.05816064,0 0,1.05059752 0,2.34625359 C0,3.64190965 1.05816064,4.69250717 2.36314396,4.69250717 C3.66812728,4.69250717 4.72628792,3.64190965 4.72628792,2.34625359 Z" id="Stroke-5"></path>
                      <line x1="9.14048198" y1="11.4003766" x2="15.4411992" y2="11.4003766" id="Stroke-3"></line>
                      <path d="M4.72628792,11.3462536 C4.72628792,10.0505975 3.66812728,9 2.36314396,9 C1.05816064,9 0,10.0505975 0,11.3462536 C0,12.6419097 1.05816064,13.6925072 2.36314396,13.6925072 C3.66812728,13.6925072 4.72628792,12.6419097 4.72628792,11.3462536 Z" id="Stroke-5"></path>
                  </g>
              </g>
          </g>
      </svg>`,
      title: 'Timeline',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }
 
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      children: data.children || [],
    };
    this.uploader = new Uploader({
      config: {
        endpoints: config.endpoints || '',
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || 'image',
        types: config.types || 'image/*',
        uploader: config.uploader || undefined
      },
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error)
    });

 
    this.CSS = {
      wrapper: 'walkthrough-nested',
    };
 
    this.nodes = {
      holder: null,
    };
  }

  onUpload(response) {
    if (response.success && response.file) {
      var lastesUploadedChild = this.data.children.find(x => x.upload_here === true)
      lastesUploadedChild.image = response.file
      lastesUploadedChild.upload_here = false
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText) {
    console.log(errorText)
    this.api.notifier.show({
      message: this.api.i18n.t('Can not upload an image, try another'),
      style: 'error'
    });
  }
 
  render() {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;
 
    const onDataChange = (newData) => {
      this.data.children = newData.children;
    }

    ReactDOM.render(
      (
        <div>
            <div className='block_name'>timeline block</div>
            <TimelineChildren
              uploader={this.uploader}
              onDataChange={onDataChange}
              readOnly={this.readOnly}
              data={this.data} />
        </div>
       
      ),
      rootNode);
    return this.nodes.holder;
  }
 
  save() {
    return this.data;
  }
}
