import ReactDOM from 'react-dom';
import GalleryChildren from './GalleryChildren';
import Uploader from './../Uploader';

export default class Gallery {
  
  static get toolbox() {
    return {
      icon: `<svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
          <g transform="translate(-1606.000000, -1822.000000)" stroke="#000000" stroke-width="1.5">
              <g id="Image" transform="translate(1607.000000, 1823.000000)">
                  <path d="M18.46,5.149 L18.46,13.3 C18.46,16.32 16.57,18.45 13.55,18.45 L4.9,18.45 C1.88,18.45 0,16.32 0,13.3 L0,5.149 C0,2.129 1.89,0 4.9,0 L13.55,0 C16.57,0 18.46,2.129 18.46,5.149 Z" id="Stroke-1"></path>
                  <path d="M2.5313,13.6807 L4.0593,12.0677 C4.5903,11.5047 5.4753,11.4777 6.0393,12.0077 C6.0563,12.0247 6.9763,12.9597 6.9763,12.9597 C7.5313,13.5247 8.4383,13.5337 9.0033,12.9797 C9.0403,12.9437 11.3373,10.1577 11.3373,10.1577 C11.9293,9.4387 12.9923,9.3357 13.7123,9.9287 C13.7603,9.9687 15.9303,12.1957 15.9303,12.1957" id="Stroke-3"></path>
                  <path d="M7.5626,6.3829 C7.5626,7.3519 6.7776,8.1369 5.8086,8.1369 C4.8396,8.1369 4.0546,7.3519 4.0546,6.3829 C4.0546,5.4139 4.8396,4.6289 5.8086,4.6289 C6.7776,4.6299 7.5626,5.4139 7.5626,6.3829 Z" id="Stroke-5"></path>
              </g>
          </g>
        </g>
      </svg>`,
      title: 'Gallery',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }
 
  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      children: data.children || [],
    };

    this.uploader = new Uploader({
      config: {
        endpoints: config.endpoints || '',
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || 'image',
        types: config.types || 'image/*',
        uploader: config.uploader || undefined
      },
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error)
    });

 
    this.CSS = {
      wrapper: 'walkthrough-nested',
    };
 
    this.nodes = {
      holder: null,
    };
  }

  onUpload(response) {
    if (response.success && response.file) {
      var lastesUploadedChild = this.data.children.find(x => x.upload_here === true)
      lastesUploadedChild.image = response.file
      lastesUploadedChild.upload_here = false
    } else {
      this.uploadingFailed('incorrect response: ' + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText) {
    console.log(errorText)
    this.api.notifier.show({
      message: this.api.i18n.t('Can not upload an image, try another'),
      style: 'error'
    });
  }
 
  render() {
    const rootNode = document.createElement('div');
    rootNode.setAttribute('class', this.CSS.wrapper);
    this.nodes.holder = rootNode;
 
    const onDataChange = (newData) => {
      this.data.children = newData.children;
    }

    ReactDOM.render(
      (
        <div>
            <div className='block_name'>gallery block</div>
            <GalleryChildren
            uploader={this.uploader}
            onDataChange={onDataChange}
            readOnly={this.readOnly}
            data={this.data} />
        </div>
       
      ),
      rootNode);
    return this.nodes.holder;
  }
 
  save() {
    return this.data;
  }
}
