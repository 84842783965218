import { useState, useEffect } from "react";
import {
  useCustom,
  useApiUrl,
  useNavigation,
  useUpdate,
} from "@pankod/refine-core";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import RouterProvider from "@pankod/refine-react-router";

export const ContactList: React.FC = () => {
  const { push, show } = useNavigation();
  const [page, setPage] = useState(1);
  const { mutate } = useUpdate();
  const [contactStatus, setContactStatus] = useState(1);

  console.log(RouterProvider.useLocation());

  const apiUrl = useApiUrl();
  const params = RouterProvider.useLocation();
  // useEffect(() => {
  //   if (params.search) {
  //     const routePage = Number(
  //       new URL(window.location.href).searchParams.get("page")
  //     );
  //     setPage(routePage);
  //   }
  //   const cts = document.querySelectorAll(".ct, .tox");
  //   cts.forEach((ct) => {
  //     ct.remove();
  //   });
  // });

  const { data, isLoading, status } = useCustom({
    url: `${apiUrl}/contacts`,
    config: {
      query: {
        status: contactStatus,
        page: page,
        limit: 30,
      },
    },
    method: "get",
  });

  const pagginationHandler = (selected) => {
    const selected_page = selected.selected + 1;
    setPage(selected_page);
    push(`contacts?page=${selected_page}&status=${contactStatus}`);
  };

  const archive = (id: any, status: any) => {
    mutate({
      resource: "contacts",
      id: id,
      values: {
        status: status == 1 ? 0 : 1,
      },
    });

    document.querySelector(`.table_line_${id}`)?.classList.add("hide_line");
  };

  return (
    <>
      <div className="page_name">
        <h3>Contacts</h3>
        <div className="page_name_actions">
          <ul className="status_bttns">
            <li>
              <button
                className={
                  "status_bttn " + (contactStatus === 1 ? "active" : "")
                }
                onClick={() => {
                  setContactStatus(1);
                  push(`contacts?page=${page}&status=1`);
                }}
              >
                Active
              </button>
            </li>
            <li>
              <button
                className={
                  "status_bttn " + (contactStatus === 0 ? "active" : "")
                }
                onClick={() => {
                  setContactStatus(0);
                  push(`contacts?page=${page}&status=0`);
                }}
              >
                Archived
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div className="page_content_wrap">
        <div className="list_page_wrap">
          <table className="list_table">
            <thead>
              <tr>
                <th>Date</th>
                <th>From</th>
                <th>Email</th>
                <th>Subject</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.data.map((item: any) => (
                <tr key={item.id} className={"table_line_" + item.id}>
                  <td>
                    {new Date(item.created_at).toLocaleString("en-US", {
                      year: "numeric",
                      month: "numeric",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                  </td>
                  <td>{item.full_name}</td>
                  <td>{item.email}</td>
                  <td>{item.subject}</td>
                  <td>
                    <div className="actions">
                      <button
                        className="view_bttn"
                        onClick={() => show("contacts", item.id)}
                      ></button>
                      <button
                        className="archive_bttn"
                        onClick={() => archive(item.id, item.status)}
                      ></button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {data?.data.pagination.pages > 1 && (
            <ReactPaginate
              className="paggination"
              previousClassName="pg_previous"
              nextClassName="pg_next"
              breakLabel="..."
              nextLabel="Next"
              onPageChange={pagginationHandler}
              pageRangeDisplayed={5}
              initialPage={data?.data.pagination.page - 1}
              disableInitialCallback={true}
              pageCount={data?.data.pagination.pages}
              previousLabel="Previous"
            />
          )}
        </div>
      </div>
    </>
  );
};
