import { useState, useEffect } from "react";
import {
  useCustom,
  useApiUrl,
  useNavigation,
  useDelete,
} from "@pankod/refine-core";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
import RouterProvider from "@pankod/refine-react-router";

export const TemplatesList: React.FC = () => {
  const { push, edit, create } = useNavigation();
  const [page, setPage] = useState(1);
  const { mutate } = useDelete();

  const apiUrl = useApiUrl();
  const params = RouterProvider.useLocation();
  useEffect(() => {
    if (params.search) {
      const routePage = Number(
        new URL(window.location.href).searchParams.get("page")
      );
      setPage(routePage);
    }
    const cts = document.querySelectorAll(".ct, .tox");
    cts.forEach((ct) => {
      ct.remove();
    });
  });

  const { data, isLoading, status } = useCustom({
    url: `${apiUrl}/templates`,
    method: "get",
  });

  const pagginationHandler = (selected) => {
    const selected_page = selected.selected + 1;
    setPage(selected_page);
    push(`news?page=${selected_page}`);
  };

  const deleteHandler = (item) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      document.querySelector(`.table_line_${item.id}`)?.classList.add("hide");
      mutate({ id: item.id, resource: "templates" });
    }
  };

  return (
    <>
      <div className="page_name">
        <h3>Templates</h3>
        <button className="blue_bttn" onClick={() => create("templates")}>
          Create new template
        </button>
      </div>
      <div className="page_content_wrap">
        <div className="list_page_wrap">
          <table className="list_table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data?.data.map((item: any) => (
                <tr key={item.id} className={"table_row table_line_" + item.id}>
                  <td>
                    <div className="large_litle">{item.title}</div>
                  </td>
                  <td>
                    <div className="actions">
                      <button
                        className="edit_bttn"
                        style={{ display: "none" }}
                        onClick={() => edit("templates", item.id)}
                      ></button>
                      <button
                        className="delete_bttn"
                        onClick={() => deleteHandler(item)}
                      ></button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
