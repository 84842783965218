import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next)
    .init({
        supportedLngs: ["en", "az"],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
        defaultNS: "common",
        fallbackLng: ["en", "az"],
    });

export default i18n;