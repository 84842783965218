import { default as React } from "react";
import ReactDOM from "react-dom";
import NestedChildren from "./NestedChildren";
import Uploader from "./../Uploader";

export default class Nested {
  static get toolbox() {
    return {
      icon: `<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
              <g transform="translate(-1570.000000, -1774.000000)" stroke="#000000" stroke-width="1.5">
                  <g id="Category" transform="translate(1571.000000, 1775.000000)">
                      <path d="M0.000858865205,3.5 C0.000858865205,0.874787053 0.0289681101,0 3.50085887,0 C6.9727494,0 7.00085887,0.874787053 7.00085887,3.5 C7.00085887,6.12521295 7.01193168,7 3.50085887,7 C-0.010214169,7 0.000858865205,6.12521295 0.000858865205,3.5 Z" id="Stroke-1"></path>
                      <path d="M11.0008589,3.5 C11.0008589,0.874787053 11.0289681,0 14.5008589,0 C17.9727494,0 18.0008589,0.874787053 18.0008589,3.5 C18.0008589,6.12521295 18.0119317,7 14.5008589,7 C10.9897858,7 11.0008589,6.12521295 11.0008589,3.5 Z" id="Stroke-3"></path>
                      <path d="M0.000858865205,14.5 C0.000858865205,11.8747871 0.0289681101,11 3.50085887,11 C6.9727494,11 7.00085887,11.8747871 7.00085887,14.5 C7.00085887,17.1252129 7.01193168,18 3.50085887,18 C-0.010214169,18 0.000858865205,17.1252129 0.000858865205,14.5 Z" id="Stroke-5"></path>
                      <path d="M11.0008589,14.5 C11.0008589,11.8747871 11.0289681,11 14.5008589,11 C17.9727494,11 18.0008589,11.8747871 18.0008589,14.5 C18.0008589,17.1252129 18.0119317,18 14.5008589,18 C10.9897858,18 11.0008589,17.1252129 11.0008589,14.5 Z" id="Stroke-7"></path>
                  </g>
              </g>
          </g>
      </svg>`,
      title: "Nested",
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      title: data.title || "",
      children: data.children || [],
    };

    this.uploader = new Uploader({
      config: {
        endpoints: config.endpoints || "",
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || "image",
        types: config.types || "image/*",
        uploader: config.uploader || undefined,
      },
      onUpload: (response) => this.onUpload(response),
      onError: (error) => this.uploadingFailed(error),
    });

    this.uploader_white = new Uploader({
      config: {
        endpoints: config.endpoints || "",
        additionalRequestData: config.additionalRequestData || {},
        additionalRequestHeaders: config.additionalRequestHeaders || {},
        field: config.field || "image",
        types: config.types || "image/*",
        uploader: config.uploader || undefined,
      },
      onUpload: (response) => this.onUploadWhite(response),
      onError: (error) => this.uploadingFailed(error),
    });

    this.CSS = {
      wrapper: "walkthrough-nested",
    };

    this.nodes = {
      holder: null,
    };
  }

  onUpload(response) {
    if (response.success && response.file) {
      var lastesUploadedChild = this.data.children.find(
        (x) => x.upload_here === true
      );
      lastesUploadedChild.image = response.file;
      lastesUploadedChild.upload_here = false;
    } else {
      this.uploadingFailed("incorrect response: " + JSON.stringify(response));
    }
  }

  onUploadWhite(response) {
    if (response.success && response.file) {
      var lastesUploadedChild = this.data.children.find(
        (x) => x.upload_here_white === true
      );
      lastesUploadedChild.image_white = response.file;
      lastesUploadedChild.upload_here_white = false;
    } else {
      this.uploadingFailed("incorrect response: " + JSON.stringify(response));
    }
  }

  uploadingFailed(errorText) {
    console.log("Image : uploading failed because of", errorText);

    this.api.notifier.show({
      message: this.api.i18n.t("Can not upload an image, try another"),
      style: "error",
    });
  }

  render() {
    const rootNode = document.createElement("div");
    rootNode.setAttribute("class", this.CSS.wrapper);
    this.nodes.holder = rootNode;

    const onDataChange = (newData) => {
      this.data.children = newData.children;
    };

    const onTitleChange = (e) => {
      this.data.title = e.target.value;
    };

    ReactDOM.render(
      <div>
        <div className="block_name">nested block</div>
        <div className="nested_title">
          <input
            type="text"
            defaultValue={this.data.title}
            placeholder="Nested block title"
            onChange={onTitleChange}
          />
        </div>
        <NestedChildren
          uploader={this.uploader}
          uploader_white={this.uploader_white}
          onDataChange={onDataChange}
          readOnly={this.readOnly}
          data={this.data}
        />
      </div>,

      rootNode
    );

    return this.nodes.holder;
  }

  save() {
    return this.data;
  }
}
