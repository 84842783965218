import ReactDOM from "react-dom";
import YouTubeVideoChildren from "./YouTubeVideoChildren";

export default class YouTubeVideo {
  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 24 24" style="enable-background:new 0 0 24 24;" xml:space="preserve" width="512" height="512">
      <g id="XMLID_184_">
        <path d="M23.498,6.186c-0.276-1.039-1.089-1.858-2.122-2.136C19.505,3.546,12,3.546,12,3.546s-7.505,0-9.377,0.504   C1.591,4.328,0.778,5.146,0.502,6.186C0,8.07,0,12,0,12s0,3.93,0.502,5.814c0.276,1.039,1.089,1.858,2.122,2.136   C4.495,20.454,12,20.454,12,20.454s7.505,0,9.377-0.504c1.032-0.278,1.845-1.096,2.122-2.136C24,15.93,24,12,24,12   S24,8.07,23.498,6.186z M9.546,15.569V8.431L15.818,12L9.546,15.569z"/>
      </g>
      </svg>`,
      title: "YouTube Video",
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;
    this.data = {
      children: data.children || [],
    };

    this.CSS = {
      wrapper: "walkthrough-nested",
    };

    this.nodes = {
      holder: null,
    };
  }

  render() {
    const rootNode = document.createElement("div");
    rootNode.setAttribute("class", this.CSS.wrapper);
    this.nodes.holder = rootNode;

    const onDataChange = (newData) => {
      this.data.children = newData.children;
    };

    ReactDOM.render(
      <div>
        <div className="block_name">youtube block</div>
        <YouTubeVideoChildren
          onDataChange={onDataChange}
          readOnly={this.readOnly}
          data={this.data}
        />
      </div>,

      rootNode
    );
    return this.nodes.holder;
  }

  save() {
    return this.data;
  }
}
