import { default as React } from 'react';

const DEFAULT_INITIAL_DATA = () => {
  return {
    children: [
      {
        "title": "",
        "description": "",
        "image": "",
        "upload_here": false
      }
    ],
  }
}
 
const GalleryChildren = (props) => {
  const [childrenData, setChildrenData] = React.useState(props.data.children.length > 0 ? props.data : DEFAULT_INITIAL_DATA);
  
  const updateChildrenData = (newData) => {
    setChildrenData(newData);
    if (props.onDataChange) {
      props.onDataChange(newData);
    }
  }
 
  const onAddChild = (e) => {
    const newData = {
      ...childrenData
    }
    newData.children.push({
      "title": "",
      "description": "",
      "image": "",
      "upload_here": false
    })
    updateChildrenData(newData);
  }
 
  const onContentChange = (index, fieldName) => {
    return (e) => {
      const newData = {
        ...childrenData
      }
      newData.children[index][fieldName] = e.target.value;
      updateChildrenData(newData);
    }
  }

  const onRemoveChild = (index) => {
    return (e) => {
      const newItems = childrenData.children.filter((child, childIndex) => {
        return childIndex !== index
      })
      childrenData.children = newItems
      const newData = {
        ...childrenData
      }
      updateChildrenData(newData);
    }
  }

  const onSelectFile = (index) => {
    props.uploader.uploadSelectedFile({
      onPreview: (src) => {
        const newData = {
          ...childrenData
        }
        newData.children[index]['image'] = {
          "url": src
        }
        newData.children[index]['upload_here'] = true
        updateChildrenData(newData);
      }
    });
  }
 
  return (
    <>
      <div className='ce_gallery'>
        {childrenData.children.map((child, index) => (
          <div key={index} className='ce_gallery_item'>
            <div 
              className='ce_gallery_preview' 
              style={{backgroundImage: `url(${child.image?.url})`}}
            >
              <div className='ce_gallery_upload' onClick={() => {onSelectFile(index)}}><span></span></div>
            </div>
            <div className='ce_gallery_desc inline_inputs'>
              <input type='text' placeholder='Please enter image title' onChange={onContentChange(index, 'title')} value={child.title}/> 
              <input type='text' placeholder='Image description (optional)' onChange={onContentChange(index, 'description')} value={child.description}/> 
            </div>
            <div onClick={onRemoveChild(index)} className='ce_delete_child'></div>
          </div>
        ))}
        {!props.readOnly &&
          <div className='ce_add_new_child' onClick={onAddChild}>Add new gallery item</div>
        }
      </div>
    </>
  );
}
 
export default GalleryChildren;