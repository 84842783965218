import Navbar from "./shared/Navbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Layout: React.FC = ({ children }) => {
 
    return (
        <div className="dashboard">
            <ToastContainer />
            <Navbar />
            <main className="main_wrap">
                <div className="page_wrap">
                        {children}
                </div>
            </main>
        </div>
    );
};
