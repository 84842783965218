import { useApiUrl } from "@pankod/refine-core";
import axios from "axios";
import { Upload } from "antd";
import "antd/lib/upload/style/index.css";

export const Uploader = (props: any) => {
  const apiUrl = useApiUrl();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("auth")}`,
  };

  if (props.value !== undefined) {
    return (
      <>
        <label className="input_lable">{props.label}</label>
        <Upload.Dragger
          {...(props.value === undefined ||
          props.value === null ||
          JSON.stringify(props.value) === "{}"
            ? {}
            : { fileList: [props.value] })}
          name="file"
          action={`${apiUrl}/attachment/upload`}
          headers={headers}
          onChange={(data) => {
            if (data.fileList.length > 0) {
              props.onChange(data.file);
              if (data.file.status == "done") {
                const uploaded_preview = {
                  uid: data.file.uid,
                  url: data.file.response.url,
                  cover: data.file.response.cover.url,
                  thumb: data.file.response.thumb.url,
                  name: data.file.name,
                  status: data.file.status,
                };
                props.onChange(uploaded_preview);
              }
            } else {
              props.onChange(null);
            }
          }}
          listType="picture"
          maxCount={1}
          onRemove={(data) => {
            axios
              .post(`${apiUrl}/attachment/remove`, data, {
                headers: headers,
              })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.log(error);
              });
            // axios
            //   .post(`${apiUrl}/attachment/upload`, data.response)
            //   .then((response) => console.log(response));
          }}
          accept="image/png, image/jpeg"
        >
          <p className="ant-upload-text">Drag & drop a file in this area</p>
        </Upload.Dragger>
      </>
    );
  } else {
    return <div className="loading"></div>;
  }
};
