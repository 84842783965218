import { useEffect, useState } from "react";
import { useForm, Controller } from "@pankod/refine-react-hook-form";
import { useSelect } from "@pankod/refine-core";
import { useTranslation } from "react-i18next";


import { Editor } from "components/editor/Editor";
import { Uploader } from "components/editor/Uploader";
import { MultilanguageInput } from "components/editor/MultilanguageInput";
import { MultilanguageTextarea } from "components/editor/MultilanguageTextarea";


export const PageCreate: React.FC = () => {
    const [activeIndex, setIndex] = useState(0)
    const [showContent, setShowContent] = useState(true)
    const [showLink, setShowLink] = useState(false)

    const { i18n } = useTranslation();

    const {
        refineCore: { onFinish, formLoading, queryResult },
        register,
        handleSubmit,
        resetField,
        control,
        formState: { errors },
        getValues,
        setValue
    } = useForm({
        mode: "onChange",
    });

    const { options } = useSelect({
        resource: "templates",
        defaultValue: queryResult?.data?.data.template.id,
        optionLabel: "title",
        optionValue: "id"
    });





    const getContent = () => {
        setShowContent(!getValues().has_content)
    }

    const parent_id = location.search.split('page_id=')[1]
    const defaulValues = {};
    (i18n.languages || []).map((lang: string, index: number) => (
       defaulValues[lang] = ''
    ))

    useEffect(() => {
        setValue("title", defaulValues);
        setValue("caption", defaulValues);
        setValue("showing_date", new Date());
        setValue("preview", null);
        setValue("background_white", null);
        setValue("background_dark", null);
        setValue("content", defaulValues); 
        setValue("visible_on_menu", true);
        setValue("ability_to_add", false);
        setValue("parent_id", parent_id);
    }, []);


    return (
        <div>
            <div className="page_name">
                <h3>Create page</h3>  
            </div>
            <div className="page_content_wrap">
                <form onSubmit={handleSubmit(onFinish)}>
                    <div className="tabs_titles">
                        <ul>
                            <li><div onClick={() => setIndex(0)} className={'tab_name' + (activeIndex==0 ? ' active' : '')}>Content</div></li>
                            <li><div onClick={() => setIndex(1)} className={'tab_name' + (activeIndex==1 ? ' active' : '')}>Settings</div></li>
                            {/* <li><div onClick={() => setIndex(2)} className={'tab_name' + (activeIndex==2 ? ' active' : '')}>Delete</div></li> */}
                        </ul>
                    </div>
                    <div className="tabs_self">
                        <div className={'tab_item' + (activeIndex==0 ? ' active' : '')}>
                            <div className="field_line">
                                <Controller
                                    {...register("title", { validate: value => value.en != '' })}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <MultilanguageInput
                                            label='Page title'
                                            placeholder='Enter page title'
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                                {errors.title && (<div className="error_message">This field is required</div>)}
                            </div>

                            <div className="field_line">
                                <Controller
                                    {...register("caption")}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <MultilanguageTextarea
                                            label='Page caption'
                                            placeholder='Enter caption'
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>

                            <div className="field_line">
                                <label className="input_lable">Does this page has content</label>
                                <label className="input_checkbox">
                                    <div className="switch" onClick={() => getContent()}>
                                        <input {...register("has_content")} type="checkbox" id="has_content" />
                                        <label htmlFor="has_content"></label>
                                    </div>
                                </label>
                            </div>
                            <div className={'field_line hidden_content' + (showContent==true ? ' active' : '')}>
                                <Controller
                                    {...register("content")}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value} }) => (
                                        <Editor
                                            label='Content'
                                            placeholder='Enter page content data'
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>

                            <div className={'field_line hidden_content' + (showLink==true ? ' active' : '')}>
                                <label className="input_lable">Link to map</label>
                                <div className="link_to_input">
                                    <p>https://afez.az/</p>
                                    <input
                                        {...register("link_to_map")}
                                        type="text"
                                        placeholder="enter link to map (use lowercase)"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={'tab_item' + (activeIndex==1 ? ' active' : '')}>
                            <div className="field_line">
                                <Controller
                                    {...register("preview")}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Uploader
                                            label='Preview image'
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div className="field_line">
                                <Controller
                                    {...register("background_white")}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Uploader
                                            label='Light background'
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div className="field_line">
                                <Controller
                                    {...register("background_dark")}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <Uploader
                                            label='Dark background'
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )}
                                />
                            </div>
                            <div className="field_line">
                                <label className="input_lable">Template</label>
                                <select
                                    defaultValue={""}
                                    {...register("template.id", { required: true })}
                                    className="simple_input"
                                >
                                    <option value={""} disabled>Please select</option>
                                    {options?.map((template) => (
                                        <option key={template.value} value={template.value}>
                                            {template.label}
                                        </option>
                                    ))}
                                </select>
                                {errors.template && (<div className="error_message">This field is required</div>)}
                            </div>
                            <div className="field_line">
                                <label className="input_lable">Does this page visible on menu</label>
                                <label className="input_checkbox">
                                    <div className="switch">
                                        <input {...register("visible_on_menu")} type="checkbox" id="visible_on_menu"  />
                                        <label htmlFor="visible_on_menu"></label>
                                    </div>
                                </label>
                            </div>
                            <div className="field_line">
                                <label className="input_lable">Ability to add new children to this page</label>
                                <label className="input_checkbox">
                                    <div className="switch">
                                        <input {...register("ability_to_add")} type="checkbox" id="ability_to_add" />
                                        <label htmlFor="ability_to_add"></label>
                                    </div>
                                </label>
                            </div>
                            <input
                                {...register("parent_id")}
                                type="hidden"
                                placeholder="Parent ID"
                                className="simple_input"
                            />
                        </div>
                        <div className={'tab_item' + (activeIndex==2 ? ' active' : '')}>
                            <b>Danger Zone</b>
                            <p>Once you delete a repository, there is no going back. Please be certain.</p>
                        </div>
                    </div>
                    <div className="save_button_area">
                        <button type="submit" className="blue_bttn">
                            <span>Save</span>
                            {formLoading && <span> | Loading... |</span>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
