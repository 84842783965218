import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

export const MultilanguageTextarea = (props: any) => {
    const { i18n } = useTranslation();
    const [inputs, setInputs] = useState({});
    const [activeIndex, setIndex] = useState(0)

    useEffect(() => {
        setInputs(props.value);
    });

    const onChange = (e) => {
        const values = { 
            ...inputs,
            [e.target.name]: e.target.value 
        }
        
        setInputs(values);
        props.onChange(values)
    }

    if(inputs !== undefined) {
        return (
            <>
                <div className="language_input_label">
                    <label className="input_lable">{props.label}</label>
                    <ul>
                        {[...(i18n.languages || [])].map((lang: string, index) => (
                            <li key={lang} onClick={() => setIndex(index)}><div className={'language_switch' + (activeIndex==index ? ' active' : '')}>{lang}</div></li>
                        ))}
                    </ul>
                </div>
                {[...(i18n.languages || [])].map((lang: string, index) => (
                    <div key={lang} className={'language_input' + (activeIndex==index ? ' active' : '')}>
                        <textarea 
                            defaultValue={inputs[lang]}
                            name={lang} 
                            placeholder={props.placeholder} 
                            className="simple_input" 
                            onChange={onChange}
                        ></textarea>
                    </div>
                ))}
            </>
        )
    }else {
        return (
            <div className="loading"></div>
        )
    }
    

    
};
