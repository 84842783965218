import { useApiUrl } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";

import ImageUploading, { ImageListType } from "react-images-uploading";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";

export const UploaderMultiple = ({ register, setValue, getValues }) => {
  const [images, setImages] = useState<any>([]);
  const apiUrl = useApiUrl();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("auth")}`,
  };

  let gallery = getValues("gallery");

  useEffect(() => {
    if (gallery) {
      setImages(gallery);
    }
  }, [gallery, getValues, setImages]);

  const onChange = (imageList: any, addUpdateIndex: any) => {
    let uploadFiles: any = [];
    let uploaded = 0;
    imageList.forEach(async (image: any, index: number) => {
      if (image.file) {
        const formData = new FormData();
        formData.append("file", image.file);
        let response = await axios.post(
          `${apiUrl}/attachment/upload`,
          formData,
          {
            headers: headers,
          }
        );
        if (response.status == 200) {
          uploadFiles.push(response.data);
          setImages([...images, ...uploadFiles]);
          setValue("gallery", [...images, ...uploadFiles]);
        }
      }
    });
  };

  const removeImage = async (removeIndex: any) => {
    let updatePhotoList = images.filter(function (item: any, index: any) {
      return index !== removeIndex;
    });
    setImages([...updatePhotoList]);
    setValue("gallery", updatePhotoList);
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setImages((array: any) => arrayMoveImmutable(array, oldIndex, newIndex));
    setValue("gallery", arrayMoveImmutable(images, oldIndex, newIndex));
  };

  return (
    <>
      <label className="input_lable">Gallery</label>
      <ImageUploading multiple value={images} onChange={onChange}>
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          <>
            <div
              className="gallery_drag"
              // style={isDragging ? { color: "red" } : undefined}
              onClick={onImageUpload}
              {...dragProps}
            >
              Drag & drop a file in this area
            </div>

            <SortableList onSortEnd={onSortEnd}>
              <div className="gallery_wrapper">
                {images.length > 0 && (
                  <>
                    {images.map((image, index) => (
                      <SortableItem key={index}>
                        <div className="gallery_image">
                          <div
                            className="gallery_image_self"
                            style={{
                              backgroundImage: `url(${image.thumb.url})`,
                            }}
                          >
                            <SortableKnob>
                              <div className="gallery_knob"></div>
                            </SortableKnob>
                            <div
                              onClick={() => removeImage(index)}
                              className="remove_image"
                            ></div>
                          </div>
                        </div>
                      </SortableItem>
                    ))}
                  </>
                )}
              </div>
            </SortableList>
          </>
        )}
      </ImageUploading>
      <input {...register("gallery")} type="hidden" />
    </>
  );
};
